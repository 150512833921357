// src/components/Tasks/TaskTreeGrid.jsx

import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Filter,
  Reorder,
  Edit,
  RowDD,
  Selection,
  Toolbar,
  ColumnChooser,
  // Freeze, // Removed Freeze as it's not part of TreeGrid
} from '@syncfusion/ej2-react-treegrid';
import { FirestoreContext } from '../../contexts/FirestoreContext';
import useAuth from '../../hooks/useAuth';
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  DatePickerComponent,
  DateRangePickerComponent,
} from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import '../../styles/App.css';

const TaskTreeGrid = ({ selectedProjectId, treegridRef, allColumns }) => {
  const { firestore } = useContext(FirestoreContext);
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  // Toolbar options including Column Chooser
  const toolbarOptions = ['ColumnChooser'];

  // Event handler for toolbar clicks (to detect ColumnChooser usage)
  const handleToolbarClick = useCallback(
    async (args) => {
      if (args.item.id === `${treegridRef.current.element.id}_columnchooser`) {
        // Column chooser opened or closed
        // Save the current column state
        const columnVisibility = treegridRef.current
          .getColumns()
          .map((col) => ({
            field: col.field,
            visible: col.visible,
          }));

        const userPrefDocRef = doc(
          firestore,
          'users',
          currentUser.uid,
          'preferences',
          'columns'
        );

        try {
          await setDoc(userPrefDocRef, { columnVisibility }, { merge: true });
          console.log('Column visibility saved successfully.');
        } catch (error) {
          console.error('Error saving column visibility:', error);
        }
      }
    },
    [firestore, currentUser, treegridRef]
  );

  // Load column visibility preferences from Firestore
  const loadColumnVisibility = useCallback(async () => {
    if (currentUser && firestore && treegridRef.current) {
      const userPrefDocRef = doc(
        firestore,
        'users',
        currentUser.uid,
        'preferences',
        'columns'
      );
      try {
        const docSnap = await getDoc(userPrefDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.columnVisibility) {
            data.columnVisibility.forEach((colVis) => {
              if (colVis.visible) {
                treegridRef.current.showColumns(colVis.field);
              } else {
                treegridRef.current.hideColumns(colVis.field);
              }
            });
            console.log('Column visibility loaded successfully.');
          }
        }
      } catch (error) {
        console.error('Error loading column visibility:', error);
      }
    }
  }, [currentUser, firestore, treegridRef]);

  useEffect(() => {
    if (selectedProjectId && firestore && currentUser) {
      setLoading(true);
      const q = query(
        collection(firestore, 'users', currentUser.uid, 'tasks'),
        where('project_id', '==', selectedProjectId)
      );
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const tasksData = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            tasksData.push({ id: doc.id, ...data });
          });
          // Initialize position if missing
          const initializedTasks = tasksData.map((task, index) => ({
            ...task,
            position: task.position ?? index,
            completed: task.completed ?? false,
            hearted: task.hearted ?? false,
            is_rendered_as_separator: task.is_rendered_as_separator ?? false,
            liked: task.liked ?? false,
            done: task.done ?? false,
            custom_properties: task.custom_properties ?? {},
          }));
          // Sort tasks based on position
          initializedTasks.sort((a, b) => a.position - b.position);
          setTasks(initializedTasks);
          setLoading(false);
        },
        (error) => {
          console.error('Error fetching tasks: ', error);
          setLoading(false);
        }
      );
      return () => {
        setTasks([]);
        unsubscribe();
      };
    } else {
      setTasks([]);
    }
  }, [firestore, selectedProjectId, currentUser]);

  const handleBooleanChange = async (field, value, rowData) => {
    const taskDocRef = doc(
      firestore,
      'users',
      currentUser.uid,
      'tasks',
      rowData.id
    );
    try {
      await updateDoc(taskDocRef, { [field]: value });
      console.log(`Field "${field}" updated successfully.`);
    } catch (error) {
      console.error(`Error updating field "${field}":`, error);
    }
  };

  const handleDateChange = async (field, value, rowData) => {
    const taskDocRef = doc(
      firestore,
      'users',
      currentUser.uid,
      'tasks',
      rowData.id
    );
    try {
      await updateDoc(taskDocRef, { [field]: value });
      console.log(`Date field "${field}" updated successfully.`);
    } catch (error) {
      console.error(`Error updating date field "${field}":`, error);
    }
  };

  const handleDateRangeChange = async (rowData) => {
    const taskDocRef = doc(
      firestore,
      'users',
      currentUser.uid,
      'tasks',
      rowData.id
    );
    try {
      await updateDoc(taskDocRef, {
        due_date_start: rowData.due_date_start,
        due_date_end: rowData.due_date_end,
      });
      console.log('Date range updated successfully.');
    } catch (error) {
      console.error('Error updating date range:', error);
    }
  };

  const booleanTemplate = (props) => {
    const value = props[props.column.field] ?? false;
    return (
      <CheckBoxComponent
        checked={value}
        change={async (e) => {
          await handleBooleanChange(props.column.field, e.checked, props);
        }}
      />
    );
  };

  const booleanEditTemplate = (props) => {
    const value = props.rowData?.[props.column.field] ?? false;
    return (
      <CheckBoxComponent
        checked={value}
        change={(e) => {
          if (props.rowData) {
            props.rowData[props.column.field] = e.checked;
          }
        }}
      />
    );
  };

  const dateTemplate = (props) => {
    const value = props[props.column.field];
    const dateValue = value ? new Date(value.seconds * 1000) : null;
    return dateValue ? dateValue.toLocaleDateString() : '';
  };

  const dateEditTemplate = (props) => {
    const value = props.rowData?.[props.column.field];
    const dateValue = value ? new Date(value.seconds * 1000) : null;
    return (
      <DatePickerComponent
        value={dateValue}
        change={(e) => {
          if (props.rowData) {
            const dateTimestamp = e.value
              ? {
                  seconds: Math.floor(e.value.getTime() / 1000),
                  nanoseconds: 0,
                }
              : null;
            props.rowData[props.column.field] = dateTimestamp;
            handleDateChange(props.column.field, dateTimestamp, props.rowData);
          }
        }}
      />
    );
  };

  const dateRangeTemplate = (props) => {
    if (!props) return null;
    const startTimestamp = props.due_date_start;
    const endTimestamp = props.due_date_end;
    const startDate = startTimestamp
      ? new Date(startTimestamp.seconds * 1000)
      : null;
    const endDate = endTimestamp ? new Date(endTimestamp.seconds * 1000) : null;
    return (
      <DateRangePickerComponent
        startDate={startDate}
        endDate={endDate}
        readOnly={true}
      />
    );
  };

  const dateRangeEditTemplate = (props) => {
    if (!props || !props.rowData) return null;
    const startTimestamp = props.rowData.due_date_start;
    const endTimestamp = props.rowData.due_date_end;
    const startDate = startTimestamp
      ? new Date(startTimestamp.seconds * 1000)
      : null;
    const endDate = endTimestamp ? new Date(endTimestamp.seconds * 1000) : null;
    return (
      <DateRangePickerComponent
        startDate={startDate}
        endDate={endDate}
        change={(e) => {
          if (props.rowData) {
            props.rowData.due_date_start = e.value[0]
              ? {
                  seconds: Math.floor(e.value[0].getTime() / 1000),
                  nanoseconds: 0,
                }
              : null;
            props.rowData.due_date_end = e.value[1]
              ? {
                  seconds: Math.floor(e.value[1].getTime() / 1000),
                  nanoseconds: 0,
                }
              : null;
            handleDateRangeChange(props.rowData);
          }
        }}
      />
    );
  };

  const editOptions = {
    allowAdding: false,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Row', // Changed from 'Normal' to 'Row' for better UX
  };

  const validationRules = {
    name: { required: true },
    assignee: { required: true },
    due_date: { required: true },
  };

  // Custom FormValidator override
  const customFormValidator = (formValidator) => {
    const originalGetErrorElement = formValidator.getErrorElement.bind(
      formValidator
    );
    formValidator.getErrorElement = (name) => {
      const element = originalGetErrorElement(name);
      return element && element.parentElement ? element : null;
    };
  };

  useEffect(() => {
    // Override FormValidator method when the component mounts
    const formValidators = document.querySelectorAll('.e-formvalidator');
    formValidators.forEach((formValidator) => {
      if (formValidator.ej2_instances && formValidator.ej2_instances[0]) {
        customFormValidator(formValidator.ej2_instances[0]);
      }
    });
  }, []);

  const renderColumns = () => {
    return allColumns.map((column) => {
      const {
        field,
        headerText,
        type,
        visible,
        // isFrozen, // Removed isFrozen to eliminate "Freeze" module warnings
        showInColumnChooser,
      } = column;
      let templates = {};
      if (
        field === 'completed' ||
        type === 'Checkbox' ||
        field === 'hearted' ||
        field === 'liked' ||
        field === 'done' ||
        field === 'is_rendered_as_separator'
      ) {
        templates = {
          template: booleanTemplate,
          editTemplate: booleanEditTemplate,
        };
      } else if (type === 'Date') {
        templates = {
          template: dateTemplate,
          editTemplate: dateEditTemplate,
        };
      } else if (field === 'due_date' || type === 'DateRange') {
        templates = {
          template: dateRangeTemplate,
          editTemplate: dateRangeEditTemplate,
        };
      }
      const columnProps = {
        field,
        headerText,
        width: field === 'name' ? '210' : '150',
        textAlign: 'Left',
        // Removed isFrozen
        // isFrozen: isFrozen,
        validationRules: validationRules[field],
        visible: visible,
        showInColumnChooser: showInColumnChooser !== false, // If undefined or true, show in Column Chooser
        ...templates,
      };
      // Set isPrimaryKey to true for the primary key column
      if (field === 'id') {
        columnProps.isPrimaryKey = true;
        columnProps.visible = false; // Hide the 'id' column
      }
      return <ColumnDirective key={field} {...columnProps} />;
    });
  };

  // Handle toolbar clicks (for ColumnChooser)
  const handleToolbarClickWrapper = (args) => {
    handleToolbarClick(args);
  };

  // Load column visibility preferences when TreeGrid is created
  const handleCreated = () => {
    loadColumnVisibility();
  };

  // Handle row drag and drop to save task order
  const handleRowDrop = async (args) => {
    const data = treegridRef.current.dataSource;
    const flatData = flattenTasks(data);

    const batch = writeBatch(firestore);

    for (let i = 0; i < flatData.length; i++) {
      const task = flatData[i];
      const newPosition = i;
      if (task.position !== newPosition) {
        // Update the task's position in the local data
        task.position = newPosition;
        // Update the task in Firestore
        const taskDocRef = doc(
          firestore,
          'users',
          currentUser.uid,
          'tasks',
          task.id
        );
        batch.update(taskDocRef, { position: newPosition });
      }
    }

    try {
      // Commit the batch
      await batch.commit();
      console.log('Task order updated successfully.');
    } catch (error) {
      console.error('Error updating task order:', error);
    }
  };

  // Helper function to flatten tasks
  const flattenTasks = (tasks) => {
    const flatList = [];
    const traverse = (nodes) => {
      nodes.forEach((node) => {
        flatList.push(node);
        if (node.subtasks && node.subtasks.length > 0) {
          traverse(node.subtasks);
        }
      });
    };
    traverse(tasks);
    return flatList;
  };

  return (
    <div className="control-pane" role="region" aria-label="Tree Grid Control">
      <div className="control-section" style={{ overflowX: 'auto' }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <TreeGridComponent
            ref={treegridRef}
            dataSource={tasks}
            childMapping="subtasks"
            height="400"
            width="100%" // Changed from 'auto' to '100%' for better responsiveness
            allowReordering={true}
            allowFiltering={true}
            allowSorting={true}
            allowRowDragAndDrop={true}
            treeColumnIndex={1} // Ensure tree column index is set
            selectionSettings={{ type: 'Multiple' }}
            editSettings={editOptions}
            filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }}
            key={selectedProjectId}
            showColumnChooser={true}
            toolbar={toolbarOptions}
            toolbarClick={handleToolbarClickWrapper} // Updated to use toolbarClick
            rowDrop={handleRowDrop}
            created={handleCreated}
          >
            <ColumnsDirective>
              {/* Include a hidden primary key column */}
              <ColumnDirective
                field="id"
                headerText="ID"
                isPrimaryKey={true}
                visible={false}
              />
              {renderColumns()}
            </ColumnsDirective>
            <Inject
              services={[
                Filter,
                Sort,
                Reorder,
                Edit,
                RowDD,
                Selection,
                Toolbar,
                ColumnChooser,
                // Freeze, // Removed Freeze
              ]}
            />
          </TreeGridComponent>
        )}
      </div>
    </div>
  );
};

export default TaskTreeGrid;
