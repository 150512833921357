// src/components/Tasks/AddTask.js

import React, { useState } from 'react';
import { addDocument } from '../../services/firestoreService';
import useAuth from '../../hooks/useAuth';
import Task from '../../models/Task';
import '../../styles/AddTaskModal.css';

const AddTask = ({ projectId, gridTasks, onTaskAdded, onClose }) => {
  const { currentUser } = useAuth();
  const [newTask, setNewTask] = useState(new Task());

  const handleAddTask = async () => {
    const nextPosition = gridTasks.length; // Get the next available position
    const taskData = {
      ...newTask,
      project_id: projectId,
      created_by: currentUser.uid,
      created_at: new Date(),
      updated_at: new Date(),
      position: nextPosition, // Assign the new position
    };
    const taskId = await addDocument(`tasks`, taskData);
    setNewTask(new Task());
    onTaskAdded(prevTasks => [...prevTasks, { id: taskId, ...taskData }]);
    onClose(); // Close the modal after adding the task
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setNewTask((prevTask) => ({
      ...prevTask,
      [name]:
        type === 'checkbox'
          ? checked
          : ['tags', 'followers', 'hearts', 'likes', 'projects', 'dependencies', 'dependents'].includes(name)
          ? value.split(',').map((item) => item.trim())
          : value,
    }));
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="sign-in-modal add-task-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Add new task</h2>
        <div className="modal-form modal-form-columns">
          <div className="form-scrollable">
            <div className="form-columns">
              <div className="form-column">
                {/* First column of fields */}
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={newTask.name}
                    onChange={handleInputChange}
                    required
                    placeholder=" "
                  />
                  <label>Task Name</label>
                  <div className="bar"></div>
                </div>
                {/* Add more fields for the first column */}
                <div className="form-group">
                  <textarea
                    name="notes"
                    value={newTask.notes}
                    onChange={handleInputChange}
                    placeholder=" "
                  ></textarea>
                  <label>Notes</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="assignee"
                    value={newTask.assignee}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Assignee</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="assignee_status"
                    value={newTask.assignee_status}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Assignee Status</label>
                  <div className="bar"></div>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="completed"
                    checked={newTask.completed}
                    onChange={handleInputChange}
                  />
                  <label>Completed</label>
                </div>
                <div className="form-group">
                  <input
                    type="datetime-local"
                    name="due_at"
                    value={newTask.due_at || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Due At</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    name="due_date_start"
                    value={newTask.due_date_start || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Due Date Start</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    name="due_date_end"
                    value={newTask.due_date_end || ''}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Due Date End</label>
                  <div className="bar"></div>
                </div>
                {/* Include other fields from Task.js as needed */}
              </div>
              <div className="form-column">
                {/* Second column of fields */}
                <div className="form-group">
                  <input
                    type="text"
                    name="tags"
                    value={newTask.tags.join(', ')}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Tags (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <textarea
                    name="html_notes"
                    value={newTask.html_notes}
                    onChange={handleInputChange}
                    placeholder=" "
                  ></textarea>
                  <label>HTML Notes</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="followers"
                    value={newTask.followers.join(', ')}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Followers (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="hearted"
                    checked={newTask.hearted}
                    onChange={handleInputChange}
                  />
                  <label>Hearted</label>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="is_rendered_as_separator"
                    checked={newTask.is_rendered_as_separator}
                    onChange={handleInputChange}
                  />
                  <label>Rendered as Separator</label>
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    name="liked"
                    checked={newTask.liked}
                    onChange={handleInputChange}
                  />
                  <label>Liked</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="hearts"
                    value={newTask.hearts.join(', ')}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Hearts (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="likes"
                    value={newTask.likes.join(', ')}
                    onChange={handleInputChange}
                    placeholder=" "
                  />
                  <label>Likes (comma-separated)</label>
                  <div className="bar"></div>
                </div>
                {/* Include other fields from Task.js as needed */}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <button className="submit-button" onClick={onClose}>
            Cancel
          </button>
          <button className="submit-button" onClick={handleAddTask}>
            Add Task
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
