// src/views/ListView.js

import React, { useState, useEffect, useRef } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import TaskTreeGrid from '../components/Tasks/TaskTreeGrid';
import '../styles/App.css';
import AddTask from '../components/Tasks/AddTask';
import TaskDetails from '../components/Tasks/TaskDetails';
import ColumnManager from '../components/Tasks/ColumnManager';
import '../styles/ListView.css';

const ListView = ({ projectId, projects }) => {
  const { tasks, subtasks } = useFirestore();
  const [selectedProject, setSelectedProject] = useState(null);
  const [taskData, setTaskData] = useState([]);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const taskDetailsRef = useRef(null);

  const treegridRef = useRef(null); // Create a ref for the TreeGridComponent

  const [allColumns, setAllColumns] = useState([]);

  useEffect(() => {
    if (projectId) {
      const project = projects.find((proj) => proj.id === projectId);
      setSelectedProject(project);
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (selectedProject) {
      setTaskData(tasks.filter((task) => task.project_id === selectedProject.id));
    }
  }, [tasks, selectedProject]);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    taskDetailsRef.current.classList.add('show');
  };

  const handleTaskDetailsClose = () => {
    setSelectedTask(null);
    taskDetailsRef.current.classList.remove('show');
  };

  const openColumnChooser = () => {
    if (treegridRef.current) {
      treegridRef.current.columnChooserModule.openColumnChooser();
    }
  };

  const handleColumnsChange = (columns) => {
    setAllColumns(columns);
  };

  const handleTaskAdded = (newTasks) => {
    setTaskData(newTasks);
    setIsAddingTask(false);
  };

  return (
    <div className="listview">
      {selectedProject && (
        <>
          <div className="task-details-wrapper">
            <div className="task-grid">
              <div className="grid-header">
                {/* ColumnManager initializes columns */}
                <ColumnManager onColumnsChange={handleColumnsChange} />
                {/* The "+" button */}
                <button className="add-column-button" onClick={openColumnChooser}>
                  +
                </button>
              </div>
              <TaskTreeGrid
                selectedProjectId={selectedProject.id}
                treegridRef={treegridRef}
                allColumns={allColumns}
              />
              {/* Add Task button directly under the TaskTreeGrid */}
              <div className="task-grid-toolbar">
                <button
                  className="add-task-button"
                  onClick={() => setIsAddingTask(true)}
                >
                  Add Task
                </button>
              </div>
            </div>
            <div
              className="task-details"
              ref={taskDetailsRef}
              onClick={handleTaskDetailsClose}
            >
              {selectedTask && (
                <TaskDetails
                  task={selectedTask}
                  onClose={handleTaskDetailsClose}
                  projects={projects}
                  subtasks={subtasks}
                />
              )}
            </div>
          </div>
          {isAddingTask && (
            <AddTask
              projectId={selectedProject.id}
              gridTasks={taskData}
              onTaskAdded={handleTaskAdded}
              onClose={() => setIsAddingTask(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ListView;
